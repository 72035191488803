import React from "react";
import markdownToHTML from "../util/markdownToHTML";
const CompanyDetails = (props) => {
  const { details } = props;
  return (
    <div className="bg-[#f7f7f7] bg-gradient-to-t from-gray-100  relative z-[1]">
      <div className=" mx-auto    ">
        <div className="flex flex-wrap items-center  bg-[#fffffff2] background-cogs relative ">
          <div className="w-full md:w-2/3 p-6 pl-10 ">
            <p
              className="text-justify text-gray-900 leading-7 "
              dangerouslySetInnerHTML={{
                __html: markdownToHTML(details?.full_description?.description),
              }}
            ></p>
          </div>

          <div className="w-full md:w-2/6  flex justify-center items-center  md:pt-0 md:pl-2  hover:scale-110 transition-all duration-300">
            <img className="p-12"
              src={details?.full_description?.description_image?.asset?.url}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;

import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BreadCrumb from "../components/BreadCrumb";
import CompanyDetails from "../components/CompanyDetails";
import SloganQuality from "../components/Brand/SloganQuality";
import markdownToHTML from "../util/markdownToHTML";
import SEO from "../components/Seo";
const CompanyPage = (props) => {
  const { data } = props;
  console.log(data);
  return (
    <Layout details={data}>
      <SEO
        pathname="/"
        title={`Nastesco Marine Pte Ltd | Company Profile`}
        keywords={["Nastesco Profile", "Nastesco Company"]}
      />
      <BreadCrumb
        list={[
          {
            title: "Company Profile",
            href: "/company",
            active: true,
          },
        ]}
      />
      <CompanyDetails details={data?.company_profile?.nodes?.[0]} />
      <SloganQuality />

      <div className="bg-slate-50  mx-auto px-16 py-12 xs:px-3 ">
        <p className="font-bold text-2xl border-b py-4">Nastesco Policy</p>
        <div className="py-7 flex flex-col gap-4">
          {data?.company_profile?.nodes?.[0]?.policy_details &&
            data?.company_profile?.nodes?.[0]?.policy_details.map(
              (part, index) => {
                return (
                  <div className="flex  item-start xs:justify-center rounded-lg bg-white overflow-hidden shadow-sm xs:flex-wrap hover:shadow-xl hover:scale-105 transition-all duration-300 ">
                    <img
                      className="h-48 w-48 hover:scale-110 transition-all duration-300"
                      src={part?.policy_image?.asset?.url}
                    />
                    <div className="py-3 px-4 xs:mt-1">
                      {/* <p className="font-semibold text-[1rem] text-slate-900 mb-1">
                        {part?.title}
                      </p> */}
                      <p
                        className="text-justify text-gray-900 leading-7 mt-4 list-disc"
                        dangerouslySetInnerHTML={{
                          __html: markdownToHTML(part?.description),
                        }}
                      ></p>
                    </div>
                  </div>
                );
              }
            )}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    settings: allSanitySettings {
      nodes {
        id
        address_details {
          address_text
          city
          country
          office_location {
            latitude
            longitude
          }
          site_email
          site_phone
          state
        }
        business_hours {
          closed
          open
        }
        copyright_year
        shipserve_details {
          logo {
            asset {
              url
            }
          }
          trade_id
        }
        site_logo {
          asset {
            url
          }
        }
        site_name
        social_links {
          facebook_link
          linkedin_link
          tweeter_link
        }
      }
    }
    company_profile: allSanityCompanyProfile {
      nodes {
        about_us
        full_description {
          description
          description_image {
            asset {
              url
            }
          }
        }
        policy_details {
          description
          policy_image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;
export default CompanyPage;

import React from "react";

const SloganQuality = (props) => {
    return (
      <span className=" text-3xl xs:text-xl px-5 py-2 pt-3 text-indigo-600 font-bold uppercase bg-slate-100 block text-center leading-[5rem] mt-5">
        <span className="animate-pulse text-7xl font-extrabold xs:text-5xl">Q</span>uality is our
        responsibility
      </span>
    );

//   return (
//     <span className=" rounded-2xl text-3xl px-5 py-2 pt-3 text-indigo-600 font-bold uppercase bg-white block text-center leading-[5rem] mt-5">
//       <span className="animate-pulse text-7xl font-extrabold">Q</span>uality{" "}
//       <br /> is <br />
//       our
//       <br />
//       responsibility
//     </span>
//   );
};

export default SloganQuality;
